import * as React from 'react'
import Layout from './../components/layout/Layout'
import SEO from './../misc/seo'
import { Link } from 'gatsby'
import './404.scss'

const NotFoundPage = () => {
  return (
    <main id="page_fourofour">
      <Layout id="layout">
        <SEO title="404" />
        <div className="page-content fourofour  ">
          <section className="top-wrapper">
            <section className="content-wrapper">
              <h2 className="title">404</h2>
              <summary>Page not found</summary>
              <br />
              <Link to="/" className="button white">
                Go to startpage
              </Link>
            </section>
          </section>
        </div>
      </Layout>
    </main>
  )
}

export default NotFoundPage
